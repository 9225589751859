import React from "react";
import Footer from "../components/Footer";
// import person_platzhalter from "../images/person_platzhalter.png";
import portrait_hro from "../images/portrait_hro.jpg";
import portrait_tro from "../images/portrait_tro.jpg";

import "./About.css";

function About() {
  return (
    <>
      <h1>Team</h1>
      <div className="team-container">
        <div className="person p2">
          <img
            src={portrait_tro}
            alt="person 2"
            className="person-image"
          />
          <div className="person-text">
            <b>Thomas Rösli</b>
            <br />
            Geschäftsleiter / Inhaber
            <br />
            thomas.roesli@roesli-caretaker.ch
            <br />
            +41 (0) 79 470 25 33
          </div>
        </div>
        <div className="person p1">
          <img src={portrait_hro} alt="person 1" className="person-image" />
          <div className="person-text">
            <b>Hans-Ulrich Rösli</b>
            <br />
            Leiter Akquisition / Kundendienst
            <br />
            hans-ulrich.roesli@roesli-caretaker.ch
            <br />
            +41 (0) 79 393 51 39
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
