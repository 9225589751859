import React from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import "./Unternehmen.css";

const fadeIn = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

function Unternehmen() {
  return (
    <>
      <h1>Unser Unternehmen</h1>
      <div className="unternehmen-container">
        {[
          {
            textClass: "title-container",
            content: "Unternehmensname",
            additionalClass: "left-content",
          },
          {
            textClass: "text-container",
            content:
              'Der Unternehmensname "roesli" repräsentiert ein inhabergeführtes Familienunternehmen mit Schweizer Wurzeln. "caretaker" steht für exzellente und professionelle Dienstleistungen im Sinne von = "Sorge halten". Das Hinzufügen von "facility services" zeigt die operativen Aspekte im Bereich des Gebäudeunterhalts.',
            additionalClass: "left-content",
          },
          {
            textClass: "title-container right-content",
            content: "Unternehmenswerte",
            additionalClass: "right-content",
          },
          {
            textClass: "text-container right-content",
            content:
              "Fokus auf kontinuierlicher Verbesserung und Innovation, um die Erwartungen der Kunden zu übertreffen und eine langfristige Kundenzufriedenheit zu gewährleisten.",
            additionalClass: "right-content",
          },
          {
            textClass: "text-container right-content",
            content:
              "Bereitstellung eines erkennbaren Mehrwerts im Bereich der Liegenschaften Dienstleistungen, der als Vertrauensversprechen für Verwaltungen und Eigentümer dient.",
            additionalClass: "right-content",
          },
          {
            textClass: "text-container right-content",
            content:
              "Ziel, Budgetplanungen für den Unterhalt einzuhalten und gegebenenfalls Ende des Jahres sogar «Kickbacks» für die Kunden zu generieren.",
            additionalClass: "right-content",
          },
          {
            textClass: "title-container",
            content: "Transparenz",
            additionalClass: "left-content",
          },
          {
            textClass: "text-container",
            content:
              'Die " Integral " Lösung sorgt für maximale Transparenz bei den zu bezahlenden Leistungen! Wir erarbeiten gemeinsam mit Ihnen, die für Ihre Liegenschaft erforderlichen Leistungen.',
            additionalClass: "left-content",
          },
          {
            textClass: "title-container",
            content: "Offener Umgang / KVP",
            additionalClass: "right-content",
          },
          {
            textClass: "text-container",
            content:
              "Ehrliche Fehlerkultur: Offene Kommunikation und Lernbereitschaft aus Fehlern für kontinuierliche Verbesserung (KVP).",
            additionalClass: "right-content",
          },
          {
            textClass: "title-container",
            content: "Qualität",
            additionalClass: "left-content",
          },
          {
            textClass: "text-container",
            content:
              "Geforderte Eigenverantwortlichkeit der Mitarbeiter: Individuelles Engagement für Qualität und Kundenzufriedenheit.",
            additionalClass: "left-content",
          },
        ].map(({ textClass, content, additionalClass }, index) => (
          <motion.div
            key={index}
            className={`unternehmen-content-row ${additionalClass}`}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }}
            variants={fadeIn}
            transition={{ duration: 1 }}
          >
            <div className={textClass}>{content}</div>
          </motion.div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Unternehmen;
