import React from "react";
import Footer from "../components/Footer";
import "./Datenschutz.css";

function Datenschutz() {
  return (
    <>
      <div className="datenschutz-container">
        <h1>Datenschutzerklärung der Firma roesli caretaker GmbH</h1>
        <p>
          Wir bei der Firma roesli caretaker GmbH nehmen den Schutz Ihrer
          persönlichen Daten sehr ernst. Der verantwortungsvolle Umgang mit
          Ihren Daten ist für uns eine Selbstverständlichkeit. In dieser
          Datenschutzerklärung erläutern wir, welche personenbezogenen Daten wir
          erheben, wie wir diese verwenden und welche Rechte Sie in Bezug auf
          Ihre Daten haben.
        </p>

        <h2>1. Verantwortliche Stelle</h2>
        <p>
          roesli caretaker GmbH
          <br />
          Thomas Rösli
          <br />
          Hofstrasse 109
          <br />
          8620 Wetzikon
          <br />
          Schweiz
          <br />
          Telefon: +41 (0) 79 393 51 39
          <br />
          E-Mail: info[at]roesli-caretaker.ch
        </p>

        <h2>2. Erhebung und Verarbeitung personenbezogener Daten</h2>
        <h3>a) Beim Besuch der Webseite</h3>
        <p>
          Beim Aufrufen unserer Webseite werden durch den auf Ihrem Endgerät zum
          Einsatz kommenden Browser automatisch Informationen an den Server
          unserer Webseite gesendet. Diese Informationen werden temporär in
          einem sogenannten Logfile gespeichert. Folgende Informationen werden
          dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung
          gespeichert:
        </p>
        <ul>
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>
            verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
            der Name Ihres Access-Providers.
          </li>
        </ul>
        <p>
          Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
        </p>
        <ul>
          <li>
            Gewährleistung eines reibungslosen Verbindungsaufbaus der Webseite
          </li>
          <li>Gewährleistung einer komfortablen Nutzung unserer Webseite</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zu weiteren administrativen Zwecken.</li>
        </ul>
        <p>
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
          DSGVO. Unser berechtigtes Interesse folgt aus den oben aufgelisteten
          Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen
          Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
        </p>

        <h3>b) Bei Nutzung unseres Kontaktformulars</h3>
        <p>
          Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns
          über ein auf der Webseite bereitgestelltes Formular Kontakt
          aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse
          erforderlich, damit wir wissen, von wem die Anfrage stammt und um
          diese beantworten zu können. Weitere Angaben können freiwillig
          getätigt werden.
        </p>
        <p>
          Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
          nach Art. 6 Abs. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
          erteilten Einwilligung.
        </p>

        <h2>3. Zwecke der Datenverarbeitung</h2>
        <p>
          Ihre personenbezogenen Daten werden für folgende Zwecke verwendet:
        </p>
        <ul>
          <li>Zur Erfüllung und Abwicklung von Vertragsverhältnissen</li>
          <li>Zur Kommunikation mit Ihnen</li>
          <li>Zur Abwicklung von Zahlungsverkehr</li>
          <li>Zur Verbesserung unserer Dienstleistungen</li>
          <li>Zur Erfüllung gesetzlicher Verpflichtungen</li>
        </ul>
        <p>
          Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
          nach Art. 6 Abs. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
          erteilten Einwilligung. Die von Ihnen im Kontaktformular eingegebenen
          Daten verwenden wir ausschliesslich für die Bearbeitung Ihrer
          spezifischen Anfrage.
        </p>

        <h2>4. Cookies</h2>
        <p>
          Unsere Webseite verwendet Cookies. Hierbei handelt es sich um kleine
          Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem
          Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
          Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen
          Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
        </p>
        <p>
          In dem Cookie werden Informationen abgelegt, die sich jeweils im
          Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies
          bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer
          Identität erhalten.
        </p>
        <p>
          Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres
          Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte
          Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer
          Webseite bereits besucht haben. Diese werden nach Verlassen unserer
          Seite automatisch gelöscht.
        </p>
        <p>
          Darüber hinaus setzen wir ebenfalls zur Optimierung der
          Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
          festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen
          Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird
          automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben
          und Einstellungen Sie getätigt haben, um diese nicht noch einmal
          eingeben zu müssen.
        </p>
        <p>
          Zum anderen setzten wir Cookies ein, um die Nutzung unserer Webseite
          statistisch zu erfassen und zum Zwecke der Optimierung unseres
          Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei
          einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie
          bereits bei uns waren. Diese Cookies werden nach einer jeweils
          definierten Zeit automatisch gelöscht.
        </p>
        <p>
          Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke
          zur Wahrung unserer berechtigten Interessen sowie der Dritter nach
          Art. 6 Abs. 1 lit. f DSGVO erforderlich.
        </p>
        <p>
          Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren
          Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer
          gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer
          Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
          jedoch dazu führen, dass Sie nicht alle Funktionen unserer Webseite
          nutzen können.
        </p>

        <h2>5. Rechtsgrundlagen der Verarbeitung</h2>
        <p>
          Wir verarbeiten Ihre Daten auf Grundlage der folgenden
          Rechtsgrundlagen:
        </p>
        <ul>
          <li>Erfüllung eines Vertrags (Art. 6 Abs. 1 lit. b DSGVO)</li>
          <li>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)</li>
          <li>
            Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 lit. c
            DSGVO)
          </li>
          <li>Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO)</li>
        </ul>

        <h2>6. Weitergabe von Daten an Dritte</h2>
        <p>
          Wir geben Ihre personenbezogenen Daten nur dann an Dritte weiter, wenn
          dies zur Erfüllung eines Vertrags oder aufgrund gesetzlicher
          Verpflichtungen erforderlich ist. Eine Weitergabe erfolgt insbesondere
          an:
        </p>
        <ul>
          <li>
            Dienstleistungspartner (z.B. IT-Dienstleister, Zahlungsdienstleister)
          </li>
          <li>
            Behörden und öffentliche Stellen, soweit wir gesetzlich dazu
            verpflichtet sind
          </li>
        </ul>

        <h2>7. Speicherdauer</h2>
        <p>
          Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die
          Erfüllung der oben genannten Zwecke erforderlich ist oder gesetzliche
          Aufbewahrungsfristen uns dazu verpflichten. Nach Wegfall des
          jeweiligen Zwecks bzw. Ablauf dieser Fristen werden die entsprechenden
          Daten routinemässig gelöscht.
        </p>

        <h2>8. Ihre Rechte</h2>
        <p>
          Sie haben das Recht, jederzeit Auskunft über die Verarbeitung Ihrer
          Daten zu verlangen. Sie können unrichtige Daten berichtigen lassen, die
          Löschung oder Sperrung Ihrer Daten verlangen und die Verarbeitung
          Ihrer Daten einschränken. Ausserdem haben Sie das Recht, der
          Verarbeitung Ihrer Daten zu widersprechen und Ihre Einwilligung zur
          Datenverarbeitung jederzeit zu widerrufen. Darüber hinaus können Sie
          Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren
          Format erhalten (Datenübertragbarkeit). Beachten Sie, dass die Ausübung
          dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen kann und
          dies Folgen wie z.B. die vorzeitige Vertragsauflösung oder
          Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig
          informieren, wo dies nicht bereits vertraglich geregelt ist.
        </p>
        <p>
          Zur Ausübung Ihrer Rechte können Sie uns unter den oben genannten
          Kontaktdaten erreichen.
        </p>

        <h2>9. Datensicherheit</h2>
        <p>
          Wir treffen geeignete technische und organisatorische Massnahmen, um
          Ihre Daten vor Verlust, Missbrauch und unbefugtem Zugriff zu schützen.
          Unsere Sicherheitsmassnahmen werden entsprechend der technologischen
          Entwicklung fortlaufend verbessert.
        </p>

        <h2>10. Änderungen der Datenschutzerklärung</h2>
        <p>
          Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu
          ändern, um sie an geänderte Rechtslagen oder bei Änderungen des
          Dienstes oder der Datenverarbeitung anzupassen. Die jeweils aktuelle
          Version der Datenschutzerklärung ist auf unserer Website verfügbar.
        </p>

        <h1>Disclaimer</h1>
        <h2>11. Haftung für Inhalte</h2>
        <p>
          Die Inhalte unserer Webseite wurden mit grösster Sorgfalt erstellt.
          Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
          übernehmen wir jedoch keine Gewähr. Als Diensteanbieter sind wir für
          eigene Inhalte nach den allgemeinen Gesetzen verantwortlich. Wir sind
          jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
          Informationen zu überwachen. Bei Bekanntwerden von Rechtsverletzungen
          werden wir diese Inhalte umgehend entfernen.
        </p>

        <h2>12. Haftung für Links</h2>
        <p>
          Unsere Webseite enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
          verantwortlich.
        </p>

        <h2>13. Urheberrecht</h2>
        <p>
          Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
          anderen Dateien auf der Website gehören ausschliesslich der Firma
          roesli caretaker GmbH oder den speziell genannten Rechtsinhabern. Für
          die Kopie, Downloads oder Nachbildung jeglicher Elemente ist die
          schriftliche Zustimmung der Urheberrechtsträger im Voraus einzuholen.
        </p>

        <p>Stand: 04.08.2024 – 13:48 Uhr</p>
      </div>
      <Footer />
    </>
  );
}

export default Datenschutz;
