import React from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import puzzle_full from "../images/puzzle_full.png";
import puzzle_1 from "../images/puzzle_1.png";
import puzzle_2 from "../images/puzzle_2.png";
import puzzle_3 from "../images/puzzle_3.png";
import puzzle_4 from "../images/puzzle_4.png";
import puzzle_5 from "../images/puzzle_5.png";
import "./Home.css";

const fadeIn = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

function Home() {
  return (
    <>
      <div className="page-container">
        <div className="content-container">
          {[
            {
              src: puzzle_full,
              textClass: "text-container-0",
              title: (
                <>
                  <h3>
                    <b>«Willkommen bei roesli caretaker – facility services»</b>
                  </h3>
                </>
              ),
              text: "Wir sind Ihr zuverlässiger Partner für Hauswartung und Liegenschaftenbetreuung. Mit unserer engagierten und erfahrenen Crew bieten wir massgeschneiderte Lösungen für Reinigung, Grünflächenpflege, Haustechnik und einen erstklassigen Kundenservice. Entdecken Sie, wie wir Ihr Zuhause oder Ihre Geschäftsräume in Bestform halten können.",
            },
            {
              src: puzzle_4,
              textClass: "text-container-4",
              title: (
                <>
                  <i>caretaker</i> <b className="color-text-blue">CLEANING</b>
                </>
              ),
              text: "Wir bieten umfassende Reinigungsdienstleistungen für Innen- und Aussenbereiche, inklusive Unterhaltsreinigung nach individuellen Kundenanforderungen, Kontrolle und Pflege der Hartumgebung der Liegenschaft. Zusätzlich stehen wir für Spezialreinigungen zur Verfügung, um besonderen Bedürfnissen gerecht zu werden.",
            },
            {
              src: puzzle_3,
              textClass: "text-container-3",
              title: (
                <>
                  <i>caretaker</i> <b className="color-text-green">GARDEN</b>
                </>
              ),
              text: "Wir pflegen und warten Grünanlagen kontinuierlich während der Vegetationsperiode und erfüllen individuelle Kundenwünsche. Unsere erfahrenen Teams und bewährten Partner stehen für Gartenänderungen, Baumschnitt und weitere Massnahmen zur Verfügung, um Ihre Aussenanlagen in bestem Zustand zu halten.",
            },
            {
              src: puzzle_5,
              textClass: "text-container-5",
              title: (
                <>
                  <i>caretaker</i> <b className="color-text-red">TECHNICS</b>
                </>
              ),
              text: "Wir bieten professionelle Haustechnikbetreuung für Ihre Immobilie, um sicherzustellen, dass alle technischen Gewerke reibungslos funktionieren. Wir sind Ihr verlässliches 'Auge' vor Ort und sorgen dafür, dass Ihr Objekt optimal betreut wird.",
            },
            {
              src: puzzle_2,
              textClass: "text-container-2",
              title: (
                <>
                  <i>caretaker</i> <b className="color-text-orange">BENEFIT</b>
                </>
              ),
              text: "Unser caretaker BENEFIT-Programm bietet pauschalisierte Dienstleistungen wie Mieterservice, Notfalldienst, Winterdienst- und Wartungsservice. Kunden können Leistungen als 'Tickets' in ihre monatliche Pauschale einbeziehen, was eine transparente Abrechnung ermöglicht. Dies entlastet administrativ, da nur 'Ausführungstickets' und ein monatliches Reporting anfallen. Nicht genutzte Tickets werden am Ende des Geschäftsjahres erstattet.",
            },
            {
              src: puzzle_1,
              textClass: "text-container-1",
              title: (
                <>
                  <i>caretaker</i> <b className="color-text-grey">LINE</b>
                </>
              ),
              text: "Unser engagierter Kundendienst, der höchste Priorität hat, wird durch die 'caretakerLine' repräsentiert. Diese zentrale Drehscheibe bietet stets einen kompetenten Ansprechpartner für Mieter, Hauswarte, externe Handwerker und Auftraggeber, um bei allen Anliegen und Sorgen unterstützend und kompetent zur Seite zu stehen.",
            },
          ].map(({ src, textClass, title, text }, index) => (
            <motion.div
              key={index}
              className="content-row"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.1 }}
              variants={fadeIn}
              transition={{ duration: 1 }}
            >
              <img
                src={src}
                alt={`Puzzle ${index + 1}`}
                className="puzzle-image"
              />
              <div className={textClass}>
                <p className="puzzle-text">
                  <p>{title}</p>
                  {text}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
