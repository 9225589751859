import React, { useState, useCallback } from "react";
import Footer from "../components/Footer";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import "./Contact.css";
import pin_icon from "../images/pin_icon.png";
import phone_icon from "../images/phone_icon.png";
import mail_icon from "../images/mail_icon.png";

function Contact() {
  const pinPosition = { lat: 47.31125395574696, lng: 8.79554983666535 };
  const INITIAL_CAMERA = {
    center: { lat: 47.31125395574696, lng: 8.79554983666535 },
    zoom: 15,
  };

  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);

  const handleCameraChange = useCallback((ev) => {
    setCameraProps(ev.detail);
  }, []);

  return (
    <>
      <h1>Kontakt</h1>
      <div className="contact-container">
        <div className="contact-column-1">
          <div className="contact-row">
            <img src={pin_icon} alt="location" className="contact-icons"></img>
            <p>
              <b>
              roesli <i>caretaker</i> GmbH
              </b>
              <br />
              Hofstrasse 109
              <br />
              8620 Wetzikon
            </p>
          </div>
          <div className="contact-row">
            <img
              src={phone_icon}
              alt="location"
              className="contact-icons"
            ></img>
            <p>
              <b>+41 79 393 51 39</b>
            </p>
          </div>
          <div className="contact-row">
            <img src={mail_icon} alt="location" className="contact-icons"></img>
            <p>
              <b>info(at)roesli-caretaker.ch</b>
            </p>
          </div>
        </div>
        <div className="contact-column-2">
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
            <div className="map-container">
              <Map
                onCameraChanged={handleCameraChange}
                zoom={cameraProps.zoom}
                center={cameraProps.center}
                mapId={process.env.REACT_APP_MAP_ID}
                mapTypeId={"hybrid"}
                options={{
                  disableDefaultUI: true,
                  zoomControl: false,
                  gestureHandling: "auto",
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                }}
              >
                <AdvancedMarker position={pinPosition}>
                  <Pin className="pin" />
                </AdvancedMarker>
              </Map>
            </div>
          </APIProvider>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
