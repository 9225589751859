import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../components/Footer";
import "./Inquiry.css";

function Inquiry() {
  const [formData, setFormData] = useState({
    firma: "",
    strasse: "",
    nummer: "",
    plz: "",
    ort: "",
    vorname: "",
    name: "",
    email: "",
    telefon: "",
    beduerfnisse: "",
    heizungsanlage: "", // Neues Feld für Heizungsanlage
    dienstleistungen: {
      gebaeudereinigung: [],
      gruenflaechen: [],
      technischerUnterhalt: [],
      dienste: [],
    },
  });

  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    console.log("Initializing EmailJS...");
    emailjs.init(process.env.REACT_APP_MAIL_PUBLIC_KEY); // Initialisiere EmailJS mit deinem öffentlichen Schlüssel
    console.log("EmailJS initialized");
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const category = e.target.dataset.category;
    if (type === "checkbox") {
      setFormData((prevData) => {
        const updatedCategory = checked
          ? [...prevData.dienstleistungen[category], value]
          : prevData.dienstleistungen[category].filter(
              (dienstleistung) => dienstleistung !== value
            );

        return {
          ...prevData,
          dienstleistungen: {
            ...prevData.dienstleistungen,
            [category]: updatedCategory,
          },
        };
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firma: formData.firma,
      strasse: formData.strasse,
      nummer: formData.nummer,
      plz: formData.plz,
      ort: formData.ort,
      vorname: formData.vorname,
      name: formData.name,
      email: formData.email,
      telefon: formData.telefon,
      beduerfnisse: formData.beduerfnisse,
      heizungsanlage: formData.heizungsanlage, // Heizungsanlage zum Template hinzufügen
      gebaeudereinigung: formData.dienstleistungen.gebaeudereinigung.join("\n"), // Zeilenumbruch statt Komma
      gruenflaechen: formData.dienstleistungen.gruenflaechen.join("\n"), // Zeilenumbruch statt Komma
      technischerUnterhalt:
        formData.dienstleistungen.technischerUnterhalt.join("\n"), // Zeilenumbruch statt Komma
      dienste: formData.dienstleistungen.dienste.join("\n"), // Zeilenumbruch statt Komma
    };

    console.log("Senden der folgenden Daten:", templateParams);

    emailjs
      .send(
        process.env.REACT_APP_MAIL_SERVICE_ID,
        process.env.REACT_APP_MAIL_TEMPLATE_ID,
        templateParams
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage("Ihre Anfrage wurde erfolgreich eingereicht.");
          setFormData({
            firma: "",
            strasse: "",
            nummer: "",
            plz: "",
            ort: "",
            vorname: "",
            name: "",
            email: "",
            telefon: "",
            beduerfnisse: "",
            heizungsanlage: "", // Neues Feld zurücksetzen
            dienstleistungen: {
              gebaeudereinigung: [],
              gruenflaechen: [],
              technischerUnterhalt: [],
              dienste: [],
            },
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Fehler beim Senden der E-Mail: " + error.text);
        }
      );
  };

  const dienstleistungen = {
    "caretaker CLEANING": {
      color: "section-title-blue",
      fieldsetClass: "form-fieldset-blue",
      category: "gebaeudereinigung",
      subsections: {
        "Eingangsbereich, Treppenhaus": [
          "Vorplätze Hauseingänge reinigen",
          "Briefkästen u. Sonnerie reinigen",
          "Anschlagkästen kontrollieren / reinigen / aktualisieren",
          "Zeitungen / Werbematerial entfernen / entsorgen",
          "Treppenhäuser trocken- / nassreinigen",
          "Laubengänge trocken- / nassreinigen",
          "Haustüren inkl. Rahmen 2-seitig reinigen",
          "Handläufe reinigen",
          "Treppengeländer reinigen",
          "Liftkabinen Böden, Kabinen, Bedienelemente u. Spiegel reinigen",
          "Liftkabinen komplett innen und Führungsschiene reinigen",
        ],
        "Nebenräume / allgemeine Flächen": [
          "Kellergänge (allg. Bereich) trocken reinigen",
          "Waschküchen kontrollieren / trocken reinigen",
          "Waschküchen Boden nass reinigen",
          "Waschmaschine / Tumbler kontrollieren / aussen reinigen",
          "Abfallbehälter Waschküche leeren, reinigen u. neu auskleiden",
          "Trocknungsräume kontrollieren / trocken reinigen",
          "Veloabstellräume kontrollieren / trocken reinigen",
          "Veloabstellräume nass reinigen",
          "Türen (allg. Bereich) inkl. Rahmen 2-seitig reinigen",
          "Leitungen, Lüftung reinigen (allg. Bereich)",
          "Hauswart- / Technikräume kontrollieren / reinigen",
          "Beleuchtungskörper reinigen (allg. Bereich)",
        ],
        Tiefgaragen: [
          "Zu- / Wegfahrt trocken reinigen",
          'Grobreinigen "Fötzeln" (allg. Flächen)',
          "Verkehrsflächen trocken reinigen (allg. Flächen)",
          "Verkehrsflächen nass reinigen (allg. Flächen)",
          "Ablaufrinnen kontrollieren / reinigen",
          "Spinnweben entfernen (Beleuchtungskörper)",
          "Garagentor / Lüftung kontrollieren",
          "Beleuchtung, Leitungen, Lüftung reinigen (allg. Bereich)",
          "Waschboxen kontrollieren / reinigen",
        ],
        "Glas- / Fassadenreinigung": [
          "Fenster Treppenhaus einseitig Innen inkl. Rahmen reinigen",
          "Fenster Treppenhaus einseitig Aussen inkl. Rahmen reinigen",
          "Eingangsverglasungen inkl. Rahmen reinigen",
          "Fenster Nebenräume zweiseitig inkl. Rahmen reinigen (allg. Bereich)",
          "Glasvordächer reinigen",
          "Glas - Oblichter reinigen",
          "Plexiglas - Velounterstände reinigen",
        ],
      },
    },
    "caretaker GARDEN": {
      color: "section-title-green",
      fieldsetClass: "form-fieldset-green",
      category: "gruenflaechen",
      subsections: {
        "Aussenbereich / Hartumgebung": [
          'Grobreinigen "Fötzeln" der Hart- / Grünumgebung',
          "Hauszugangswege / Vorplätze Hauseingänge reinigen",
          "Aussentreppen reinigen",
          "Containerplätze reinigen",
          "Dolen / Ablaufrinnen kontrollieren / reinigen",
          "Kinderspielplatz inkl. Spielgeräte - Sichtkontrolle / reinigen",
          "Besucher-PP reinigen",
          "Zufahrten Besucher-PP reinigen",
          "Lichtschächte (allg. Bereich) trocken reinigen / Abläufe kontrollieren",
          "Untersichten Balkone reinigen",
          "Aussenbeleuchtungskörper reinigen",
          "Container am Abfuhrtag bereit- u. zurückstellen",
        ],
        "Aussenbereich / Grünumgebung": [
          "Rasen mähen inkl. Ränder trimmern",
          "Rabatten unkrauten",
          "Kanten schneiden",
          "Hecken schneiden",
          "Grünflächen lauben Herbst",
          "Grünflächen Restlaub entfernen Frühling",
          "Sommerschnitt an Sträuchern < 5m (Formschnitt)",
          "Winterschnitt an Sträuchern < 5m (Auslichtungsschnitt)",
          "Bäume schneiden > 5m",
          "Sträucher, Bäume u. Rabatten wässern",
          "Pflanztröge unkrauten / pflegen",
          "Grüngutmulde Aufgebot Leerung",
        ],
      },
    },
    "caretaker TECHNICS": {
      color: "section-title-red",
      fieldsetClass: "form-fieldset-red",
      category: "technischerUnterhalt",
      subsections: {
        Heizung: [
          "Sichtkontrolle Anlage u. Leitungen auf Dichtigkeit überprüfen",
          "Wassersäule prüfen u. auffüllen (inkl. Demineralisierungspatrone)",
          "Lauf- / Sichtkontrolle aller Pumpen",
          "Brauchwarmwasserspeicher kontrollieren (inkl. Legionellenschaltung)",
          "Heizung umschalten Sommer- / Winterzeit",
          "Frischluftzufuhr kontrollieren / reinigen",
          "Tankfüllung prüfen / melden",
          "Anlage, Kessel, Armaturen u. Leitungen reinigen",
          "Heizungsraum - Bodenflächen reinigen",
        ],
        "Technik allgemein": [
          "Beleuchtung kontrollieren u. bei Bedarf Leuchtmittel ersetzen",
          "Türschliesser / Schlösser einstellen",
          "Aussenwasserleitungen entleeren / füllen",
          "Enthärtungsanlage Salz kontrollieren / füllen",
          "Enthärtungssalz bestellen / liefern / einlagern",
          "Schmutzwasserpumpe kontr. (inkl. Ein / Aus / Alarm Schwimmer)",
          "Gaszähler ablesen u. Verwaltung melden",
          "Armaturen (EW / Wasser) reinigen",
          "Zeitschaltuhren Sommer / - Winterzeit kontrollieren / einstellen",
          "Kaltwasserfilter kontrollieren / spülen / Ersatzmeldung",
          "Flachdach Sichtkontrolle Abschlüsse / Abläufe freihalten",
          "Waschkarten Mieter laden",
          "Instandhaltung allg. Flächen, Anlagen u. Mieter (Bagatellen)",
        ],
      },
    },
    "caretaker BENEFIT": {
      color: "section-title-orange",
      fieldsetClass: "form-fieldset-orange",
      category: "dienste",
      subsections: {
        "": [
          "Mieterinstandsetzungs-Service",
          "Winterdienst-Service",
          "Pikett-/ Notfalldienst-Service (365 / 24)",
        ],
      },
    },
  };

  return (
    <>
      <div className="inquiry-container">
        <h1>Ihre Anfrage</h1>
        <p>
          Hier können Sie <b>Unverbindlich</b> Ihre Anfrage, in Form von unserem
          Standard Pflichtheft abgeben. Ebenso können Sie Ihre Kontaktdaten
          angeben, damit wir uns schnellstmöglich mit Ihnen in Verbindung setzen
          können.
        </p>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form className="inquiry-form" onSubmit={handleSubmit}>
          <label className="form-label">
            Firma
            <input
              type="text"
              name="firma"
              placeholder='z. B. "roesli caretaker GmbH"'
              value={formData.firma}
              onChange={handleChange}
            />
          </label>
          <label className="form-label">
            Adresse der Liegenschaft
            <div className="grid-container">
              <input
                type="text"
                name="strasse"
                placeholder="Strasse"
                value={formData.strasse}
                onChange={handleChange}
              />
              <input
                type="text"
                name="nummer"
                placeholder="Hausnummer"
                value={formData.nummer}
                onChange={handleChange}
              />
              <input
                type="text"
                name="plz"
                placeholder="PLZ"
                value={formData.plz}
                onChange={handleChange}
              />
              <input
                type="text"
                name="ort"
                placeholder="Ort"
                value={formData.ort}
                onChange={handleChange}
              />
            </div>
          </label>
          <div className="grid-container">
            <label className="form-label">
              Vorname
              <input
                type="text"
                name="vorname"
                placeholder='z. B. "Thomas"'
                value={formData.vorname}
                onChange={handleChange}
              />
            </label>
            <label className="form-label">
              Name
              <input
                type="text"
                name="name"
                placeholder='z. B. "Rösli"'
                value={formData.name}
                onChange={handleChange}
              />
            </label>
          </div>
          <label className="form-label">
            E-Mail
            <input
              type="email"
              name="email"
              placeholder='z. B. "info@roesli-caretaker.ch"'
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label className="form-label">
            Telefon <span className="optional">(optional)</span>
            <input
              type="text"
              name="telefon"
              placeholder='z. B. "+41 79 393 51 39"'
              value={formData.telefon}
              onChange={handleChange}
            />
          </label>

          {/* caretaker CLEANING */}
          <fieldset className="form-fieldset form-fieldset-blue">
            <legend className="section-title section-title-blue">
              caretaker CLEANING
            </legend>
            {Object.entries(
              dienstleistungen["caretaker CLEANING"].subsections
            ).map(([subsection, services], subsectionIndex) => (
              <div key={subsectionIndex}>
                <div className="subsection-title">{subsection}</div>
                {services.map((service, serviceIndex) => (
                  <label key={serviceIndex}>
                    <input
                      type="checkbox"
                      name="dienstleistungen"
                      value={service}
                      data-category="gebaeudereinigung"
                      checked={formData.dienstleistungen.gebaeudereinigung.includes(
                        service
                      )}
                      onChange={handleChange}
                    />
                    {service}
                  </label>
                ))}
              </div>
            ))}
          </fieldset>

          {/* caretaker GARDEN */}
          <fieldset className="form-fieldset form-fieldset-green">
            <legend className="section-title section-title-green">
              caretaker GARDEN
            </legend>
            {Object.entries(
              dienstleistungen["caretaker GARDEN"].subsections
            ).map(([subsection, services], subsectionIndex) => (
              <div key={subsectionIndex}>
                <div className="subsection-title">{subsection}</div>
                {services.map((service, serviceIndex) => (
                  <label key={serviceIndex}>
                    <input
                      type="checkbox"
                      name="dienstleistungen"
                      value={service}
                      data-category="gruenflaechen"
                      checked={formData.dienstleistungen.gruenflaechen.includes(
                        service
                      )}
                      onChange={handleChange}
                    />
                    {service}
                  </label>
                ))}
              </div>
            ))}
          </fieldset>

          {/* caretaker TECHNICS */}
          <fieldset className="form-fieldset form-fieldset-red">
            <legend className="section-title section-title-red">
              caretaker TECHNICS
            </legend>

            {/* Textfeld für Heizungsanlage */}
            <div className="form-label full-width">
              <label htmlFor="heizungsanlage">
                Teilen Sie uns mit, was für eine Heizungsanlage Sie auf Ihrer
                Liegenschaft haben:
              </label>
              <textarea
                id="heizungsanlage"
                name="heizungsanlage"
                placeholder="Beschreiben Sie Ihre Heizungsanlage..."
                value={formData.heizungsanlage}
                onChange={handleChange}
              ></textarea>
            </div>

            {Object.entries(
              dienstleistungen["caretaker TECHNICS"].subsections
            ).map(([subsection, services], subsectionIndex) => (
              <div key={subsectionIndex}>
                <div className="subsection-title">{subsection}</div>
                {services.map((service, serviceIndex) => (
                  <label key={serviceIndex}>
                    <input
                      type="checkbox"
                      name="dienstleistungen"
                      value={service}
                      data-category="technischerUnterhalt"
                      checked={formData.dienstleistungen.technischerUnterhalt.includes(
                        service
                      )}
                      onChange={handleChange}
                    />
                    {service}
                  </label>
                ))}
              </div>
            ))}
          </fieldset>

          {/* caretaker BENEFIT */}
          <fieldset className="form-fieldset form-fieldset-orange">
            <legend className="section-title section-title-orange">
              caretaker BENEFIT
            </legend>
            {Object.entries(
              dienstleistungen["caretaker BENEFIT"].subsections
            ).map(([subsection, services], subsectionIndex) => (
              <div key={subsectionIndex}>
                <div className="subsection-title">{subsection}</div>
                {services.map((service, serviceIndex) => (
                  <label key={serviceIndex}>
                    <input
                      type="checkbox"
                      name="dienstleistungen"
                      value={service}
                      data-category="dienste"
                      checked={formData.dienstleistungen.dienste.includes(
                        service
                      )}
                      onChange={handleChange}
                    />
                    {service}
                  </label>
                ))}
              </div>
            ))}
          </fieldset>

          <label className="form-label">
            Teilen Sie uns hier, Ihre besonderen Bedürfnisse mit:{" "}
            <span className="optional">(optional)</span>
            <textarea
              name="beduerfnisse"
              placeholder="Tippen Sie hier etwas ein..."
              value={formData.beduerfnisse}
              onChange={handleChange}
            ></textarea>
          </label>
          <button type="submit">Absenden</button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default Inquiry;
