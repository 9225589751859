import React from "react";
import Footer from "../components/Footer";

function Error() {
  return (
    <div>
      <h1>404 Seite nicht gefunden</h1>
      <Footer />
    </div>
  );
}

export default Error;
