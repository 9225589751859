import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Inquiry from "./pages/Inquiry";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
// import AGB from "./pages/Agb";
import Error from "./pages/Error";
import Unternehmen from "./pages/Unternehmen";
import logo from "./logo.png";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar logo={logo} />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/company" element={<Unternehmen />} />
          <Route path="/inquiry" element={<Inquiry />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          {/* <Route path="/agb" element={<AGB />} /> */}
          <Route path="/*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
