import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Navbar.css";
import bannerImage1 from ".././images/bannerImage1.png";
import bannerImage2 from ".././images/bannerImage2.png";
import bannerImage3 from ".././images/bannerImage3.png";
import bannerImage4 from ".././images/bannerImage4.png";
import bannerImage5 from ".././images/bannerImage5.png";

function Navbar({ logo }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="navbar-container">
      <div className="banner">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="banner-text">Unser Nachhaltigkeitsversprechen – Wir schonen Ihre Zeit und Ihre
          Kosten …
        </div>
        <img
          src={bannerImage1}
          alt="BannerImage 1"
          className="banner-image-container banner-image-1"
        />
        <img
          src={bannerImage2}
          alt="BannerImage 2"
          className="banner-image-container banner-image-2"
        />
        <img
          src={bannerImage3}
          alt="BannerImage 3"
          className="banner-image-container banner-image-3"
        />
        <img
          src={bannerImage4}
          alt="BannerImage 4"
          className="banner-image-container banner-image-4"
        />
        <img
          src={bannerImage5}
          alt="BannerImage 5"
          className="banner-image-container banner-image-5"
        />
        <div className="color-fade-1"></div>
        <div className="color-fade-2"></div>
        <div className="color-fade-3"></div>
        <div className="color-fade-4"></div>
        <div className="color-fade-5"></div>
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
      </div>
      <nav className="navbar">
        <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
          <li>
            <Link to="/" onClick={toggleMenu} className="nav-link">
              Home
            </Link>
          </li>
          <li
            className="dropdown"
            onMouseEnter={toggleDropdown}
            onMouseLeave={toggleDropdown}
            onClick={toggleDropdown}
          >
            <span className="nav-link">Über uns</span>
            <ul className={`dropdown-menu ${dropdownOpen ? "open" : ""}`}>
              <li>
                <Link to="/about" onClick={toggleDropdown} className="nav-link">
                  Team
                </Link>
              </li>
              <li>
                <Link to="/about/company" onClick={toggleMenu} className="nav-link">
                  Unternehmen
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/inquiry" onClick={toggleMenu} className="nav-link">
              Ihre Anfrage
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={toggleMenu} className="nav-link">
              Kontakt
            </Link>
          </li>
        </ul>
      </nav>
      <Outlet className="outlet" />
    </div>
  );
}

export default Navbar;
