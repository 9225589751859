import React from "react";
import Footer from "../components/Footer";
import puzzle_full from "../images/puzzle_full.png";
import "./Impressum.css";

function Impressum() {
  return (
    <div>
      <h1>Impressum</h1>
      <img src={puzzle_full} alt="logo" className="impressum-logo"></img>
      <p>
        <b>
          roesli <i>caretaker</i>
        </b>
        <br />
        Hofstrasse 109
        <br />
        8630 Wetzikon
        <br />
        Schweiz
      </p>
      <p>
        Info(at)roesli-caretaker.ch
        <br />
        +41 (0) 79 393 51 39
      </p>
      <Footer />
    </div>
  );
}

export default Impressum;
